<template>
  <div class="nedetel row flex-row-center">
    <Banner v-scrollanimation></Banner>

    <div class="fondo" v-scrollanimation></div>
    <div class="middle flex-row-center align-center mapa" v-scrollanimation>
      <div class="cont-img">
        <img src="../../assets/img/home/mapa-nedetel.png" alt="" />
      </div>
    </div>
    <div
      class="middle flex-column-center align-column-center txt"
      v-scrollanimation
    >
      <div class="info">
        <span class="title_section titulo">¿Por qué trabajar</span>
        <span class="title_section titulo">con nedetel?</span>
        <div class="barra">
          <span></span>
        </div>
        <div class="subinfo flex-column-center align-column-center">
          <div class="slider">
            <div :key="s.key" v-for="s in slide.slides.items" :class="getClassSlideItem(s.index)">
              <div
                :key="'item_' + pos"
                v-for="(i,pos) in s.item"
                :class="getClassItem()"
                class="flex-row-center align-row-center"
                v-show="1 !== null"
              >
                <div class="middle flex-column-center a">
                  <div class="texto flex-row-center" v-for="(txt, index) in i.a" :key="index" >
                    <div class="circle" v-if="txt !== ''">
                      <span></span>
                    </div>
                    <span class="content">{{txt}}</span>
                  </div>
                </div>
                <div class="middle flex-column-center b">
                  <div class="texto flex-row-center" v-for="(txt, index) in i.b" :key="index" >
                    <div class="circle" v-if="txt !== ''">
                      <span></span>
                    </div>
                    <span class="content">{{txt}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
          <div
            :key="btn.key"
            v-for="btn in slide.slides.buttons"
            :class="getClassButton(btn.index)"
            @click="changeActiveSection(btn.index)"
          ></div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../home/Banner.vue";

export default {
  name: "Nedetel",
  components: {
    Banner
  },
  data: function() {
    return {
      it: [
        {
          a: [
            "Somos una compañía con más de 15 años de experiencia, en constante crecimiento y estable en términos financieros.",
            "En Ecuador somos el carrier con la red más nueva y moderna, somos los únicos operadores que tenemos 2 redes de transporte totalmente independientes."
          ],
          b: [
            "Impulsamos la mejor experiencia de internet con nuestro gran ecosistema de CDN, diseñado para ofrecer contenido exclusivo con mayor velocidad y rendimiento.",
            "Con infraestructura optimizada, garantizamos una conexión más rápida y eficiente para nuestros usuarios."
          ]
        },
        {
          a: [
            "Gestión y monitoreo de la red 24x7x365 a través de nuestro NOC con personal propio.",
            "Ofrecemos Latencias iguales o menores a 70 ms.",
            "SLA 99.95%.",
            "Contamos con cinco salidas internacionales, tres submarinas y dos terrestres, tenemos presencia en el NAP de Ecuador, Bogotá, Miami y nos interconectamos a más de 8 TIER 1."
          ],
          b: [
            "Tenemos presencia en: NAP.EC (Ecuador), NAP de las Americas (Miami), BG1 (Bogota), NYIIX (New York), IX.Br (Fortaleza), AMS-IX (Amsterdam), PoP(Caracas).",
            "Gestión y monitoreo de la red 24x7x365 a través de nuestro NOC con personal propio.",
            ""
          ]
        }
      ],
      slide: {
        size: 0,
        slides: {
          active: 0,
          buttons: [],
          items: []
        }
      }
    };
  },
  beforeMount: function() {
    this.loadItems();
  },
  methods: {
    getClassSlideItem: function(index) {
      return {
        slide: true,
        active: this.slide.slides.active === index
      };
    },
    getClassItem: function() {
      return {
        item: true
      };
    },
    getClassButton: function(index) {
      return {
        shadow: true,
        button: true,
        active: this.slide.slides.active === index
      };
    },
    changeActiveSection: function(index) {
      this.slide.slides.active = index;
    },
    loadItems: function() {
      this.slide.size = this.it.length;
      let indices = 0;
      for (let i = 0; i < this.slide.size; i += 1) {
        let aux = {
          key: "slide_" + i,
          index: indices,
          item: []
        };
        for (let j = 0; j < 1; j++) {
          aux.item.push(i + j < this.slide.size ? this.it[i + j] : null);
        }
        this.slide.slides.items.push(aux);
        indices += 1;
      }
      for (let j = 0; j < this.slide.slides.items.length; j++) {
        this.slide.slides.buttons.push({
          key: "button_" + j,
          index: j
        });
      }
    }
  }
};
</script>

<style>
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1s ease-in;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}

.nedetel {
  width: 100%;
  height: 60vw;
  position: relative;
}

.nedetel .fondo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  transition-delay: 0.1s;

  background-image: url("../../assets/img/home/fondo-nedetel.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.nedetel .mapa {
  justify-content: center;
  align-items: flex-end;
  position: relative;
  transition-delay: 0.2s;
}

.nedetel .txt {
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 8vw;
  height: calc(100% - 4vw);
  transition-delay: 0.2s;
}

.nedetel .mapa .cont-img {
  width: 165%;
  height: 40vw;
  position: absolute;
  z-index: -1;
  left: 0vw;
}

.nedetel .mapa .cont-img img {
  width: 100%;
  height: 100%;
}

.nedetel .info {
  color: #fff;
}

.nedetel .info span {
  display: block;
}

.nedetel .info .titulo {
  font-size: 3.5vw;
  text-transform: uppercase;
  width: calc(100% - 2vw);
  padding-left: 2vw;
}

.nedetel .info .barra {
  width: calc(80% - 2vw);
  position: relative;
  margin: 2vw 0 2vw 2vw;
}

.nedetel .info .barra span {
  width: 100%;
  height: 2px;
  display: block;
  background: #fff;
}

.nedetel .subinfo {
  justify-content: flex-start;
  align-items: flex-start;
}

.nedetel .subinfo .slider { 
  position: relative;
  width: 100%;
  height: 20vw;
  margin: 0 auto;
  transition: all 0.1s 0.1s ease-in-out;
}

.nedetel .subinfo .slider .slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;

  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s 0.3s ease;
}

.nedetel .subinfo .slider .slide.active {
  z-index: 1;
  opacity: 1;
  transition: all 0.3s 0.3s ease;
}

.nedetel .subinfo .slider .slide .texto {
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2vw;
}

.nedetel .subinfo .slider .slide .middle.a {
  width: calc(63% - 2vw);
  height: 100%;
  padding-right: 1.5vw;
  justify-content: flex-start;
}

.nedetel .subinfo .slider .slide .middle.b {
  width: calc(63% - 2vw);
  height: 100%;
  padding-left: 1.5vw;
  justify-content: flex-start;
}

.nedetel .subinfo .slider .slide .texto .circle {
  width: calc(100% - 90%);
  height: calc(100% - 0.5vw);
  padding-top: 0.5vw;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
}

.nedetel .subinfo .slider .slide .texto .circle span {
  background: #fff;
  width: 0.6vw;
  height: 0.6vw;
  border-radius: 50%;
}

.nedetel .subinfo .slider .slide .texto .content {
  height: 100%;
  width: calc(100% - 10%);
  font-size: 1vw;
  text-align: justify;
  font-family: montserrat_regular;
}

.nedetel .subinfo .buttons {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding-top: 5vw;
}
.nedetel .subinfo .buttons .button {
  background: #f2f2f2;
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 50%;
  margin: 0 0.2vw;
  cursor: pointer;
  transition: all 0.3s 0.3s ease;
}
.nedetel .subinfo .buttons .button:active {
  transform: scale(0.98);
}
.nedetel .subinfo .buttons .button.active {
  background: #fff;
  width: 1.5vw;
  height: 1.5vw;
  transition: all 0.3s 0.3s ease;
}

@media only screen and (max-width: 320px) {
  .nedetel {
    flex-flow: column;
    height: auto;
  }

  .nedetel .mapa {
    width: 100%;
    justify-content: center;
    align-content: center;
  }

  .nedetel .mapa .cont-img {
    width: 100%;
    margin-top: 16vw;
    z-index: 0;
    height: auto;
    display: flex;
    position: relative;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .nedetel .txt {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .nedetel .txt .info {
    width: 90%;
    margin-bottom: 2vw;
  }

  .nedetel .info .titulo {
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: 4vw;
  }

  .nedetel .info .barra {
    margin: 2vw auto 4vw auto;
    display: flex;
    flex-flow: row;
    width: 80%;
    align-items: center;
    justify-content: center;
  }
  .nedetel .subinfo .slider .slide .texto .content {
    font-size: 2vw;
  }
  .nedetel .subinfo .slider {
    height: 24vw;
  }
  .nedetel .subinfo .buttons .button {
    margin: 0 0.8vw;
    width: 1.1vw;
    height: 1.1vw;
  }
  .nedetel .subinfo .buttons .button.active {
    width: 1.8vw;
    height: 2vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .nedetel {
    flex-flow: column;
    height: auto;
  }

  .nedetel .mapa {
    width: 100%;
    justify-content: center;
    align-content: center;
  }

  .nedetel .mapa .cont-img {
    width: 100%;
    margin-top: 16vw;
    z-index: 0;
    height: auto;
    display: flex;
    position: relative;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .nedetel .txt {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .nedetel .txt .info {
    width: 90%;
    margin-bottom: 2vw;
  }

  .nedetel .info .titulo {
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: 4.2vw;
  }

  .nedetel .info .barra {
    margin: 2vw auto 4vw auto;
    display: flex;
    flex-flow: row;
    width: 80%;
    align-items: center;
    justify-content: center;
  }
  .nedetel .subinfo .slider .slide .texto .content {
    font-size: 2vw;
  }
  .nedetel .subinfo .slider {
    height: 28vw;
  }
  .nedetel .subinfo .buttons .button {
    margin: 0 0.8vw;
    width: 1.1vw;
    height: 1.1vw;
  }
  .nedetel .subinfo .buttons .button.active {
    width: 1.8vw;
    height: 2vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .nedetel {
    flex-flow: column;
    height: auto;
  }

  .nedetel .mapa {
    width: 100%;
    justify-content: center;
    align-content: center;
  }

  .nedetel .mapa .cont-img {
    width: 100%;
    margin-top: 16vw;
    z-index: 0;
    height: auto;
    display: flex;
    position: relative;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .nedetel .txt {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .nedetel .txt .info {
    width: 90%;
    margin-bottom: 2vw;
  }

  .nedetel .info .titulo {
    width: 100%;
    padding: 0;
    text-align: center;
    font-size: 4vw;
  }

  .nedetel .info .barra {
    margin: 2vw auto 4vw auto;
    display: flex;
    flex-flow: row;
    width: 80%;
    align-items: center;
    justify-content: center;
  }
  .nedetel .subinfo .slider .slide .texto .content {
    font-size: 2vw;
  }
  .nedetel .subinfo .slider {
    height: 30vw;
  }
  .nedetel .subinfo .buttons .button {
    margin: 0 0.8vw;
    width: 1.1vw;
    height: 1.1vw;
  }
  .nedetel .subinfo .buttons .button.active {
    width: 1.8vw;
    height: 2vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  
}
</style>